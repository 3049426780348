<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: false,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.printer.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.printer.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
  },
  methods: {
    getmessagelist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getnotifymessage",
            page: that.page,
            limit: that.limit,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.messagelist = response.data.list;
          that.messagetotal = response.data.count * 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12" v-loading="loading">
        <div class="row">
          <div class="col-12">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ $t("menuitems.clouds.printer.setting.text") }}</span>
              </div>
              <div>
                
              </div>
            </el-card>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ $t("menuitems.clouds.printer.template.text") }}</span>
              </div>
              <div>
                
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>